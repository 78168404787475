
import { Options, Vue } from "vue-class-component";
import { articles } from "@/assets/articles";
import CardComponent from "@/components/CardComponent.vue";

@Options({
    data() {
        return {
            articles,
        };
    },
    components: {
        CardComponent,
    },
})
export default class About extends Vue {}
