export const articles = [
    {
        title: "Entrega de reconocimiento especial al titular de PROTUR",
        date: "miércoles, 13 de octubre de 2021",
        author: "Guillermo Cruz Fuente",
        img: "https://cdn.pixabay.com/photo/2021/11/13/19/27/architecture-6792169_960_720.jpg",
        abstract:
            "El Presidente Municipal de San Marcos, Tomás Hernández Palma acompañado de ediles, hicieron entrega de un reconocimiento especial.",
    },
    {
        title: "El Centro Cultural La Sanmarqueña abre sus puertas",
        date: "miércoles, 13 de octubre de 2021",
        author: "Guillermo Cruz Fuente",
        img: "https://cdn.pixabay.com/photo/2019/12/19/03/05/christmas-4705290_960_720.jpg",
        abstract:
            "El Centro Cultural La Sanmarqueña abre sus puertas nuevamente para sus cursos de manera gratuita.",
    },
    {
        title: "Iniciaron los trabajos de limpieza y mantenimiento de los panteones",
        date: "miércoles, 13 de octubre de 2021",
        author: "Guillermo Cruz Fuente",
        img: "https://cdn.pixabay.com/photo/2017/03/28/12/21/autumn-2182010_960_720.jpg",
        abstract:
            "iniciaron los trabajos de limpieza y mantenimiento de los panteones en la cabecera municipal ubicados en la Colonia Revolución y Colonia Emiliano Zapata respectivamente.",
    },
    {
        title: "Se realiza la primera reunión de evaluación con los diferentes directores de área, del gobierno municipal",
        date: "miércoles, 13 de octubre de 2021",
        author: "Guillermo Cruz Fuente",
        img: "https://cdn.pixabay.com/photo/2016/02/03/17/38/coffee-break-1177540_960_720.jpg",
        abstract:
            "Se realiza la primera reunión de evaluación con los diferentes directores de área, del gobierno municipal que encabeza el licenciado Tomás Hernández Palma, dónde trataron diferentes temas de la agenda de trabajo en beneficio de la ciudadanía.",
    },
    {
        title: "Se llevó a cabo la primer reunión de Comisarios Municipales y Policías Rurales",
        date: "miércoles, 13 de octubre de 2021",
        author: "Guillermo Cruz Fuente",
        img: "https://cdn.pixabay.com/photo/2019/05/18/19/37/france-4212403_960_720.jpg",
        abstract:
            "El Honorable Cabildo de San Marcos, que preside Tomás Hernández Palma, llevó a cabo la primer reunión de Comisarios Municipales y Policías Rurales, donde se presentaron a los nuevos funcionarios municipales.",
    },
    {
        title: "Entrega de 15 tinacos de manera gratuita a familias de bajos recursos económicos",
        date: "miércoles, 13 de octubre de 2021",
        author: "Guillermo Cruz Fuente",
        img: "https://endomex.com.mx/wp-content/uploads/2012/06/TINACO-TVN-1100-EDITADA.jpg",
        abstract:
            "La dirección de Desarrollo Social a través de la gestión realizada ante el Gobierno del Estado, hizo entrega de 15 tinacos de manera gratuita a familias de bajos recursos económicos de nuestro municipio.",
    },
    {
        title: "Elementos de Protección Civil atendieron el reporte de un incendio",
        date: "miércoles, 13 de octubre de 2021",
        author: "Guillermo Cruz Fuente",
        img: "https://cdn.pixabay.com/photo/2018/04/03/20/04/ehrenamt-3287867_960_720.jpg",
        abstract:
            "Esta tarde, elementos de Protección Civil atendieron el reporte de un incendio en una tortillería en la Colonia Quinta Sección, el cual fue provocado por una fuga de gas. Todo quedo bajo control y afortunadamente no se reportaron personas lesionadas.",
    },
    {
        title: "Presentación de los directores de Seguridad Pública Municipal",
        date: "miércoles, 13 de octubre de 2021",
        author: "Guillermo Cruz Fuente",
        img: "https://cdn.pixabay.com/photo/2016/08/16/09/53/international-conference-1597531_960_720.jpg",
        abstract:
            "Presentación de los directores de Seguridad Pública Municipal: Gerardo Suástegui Nava, Tránsito Municipal: Miguel Ángel Gutiérrez Guerrero, Protección Civil: Diego Armando Valente Pineda, y Servicios Públicos: Eulalio Gutierrez Castro.",
    },
    {
        title: "Entrega de reconocimiento especial al titular de PROTUR",
        date: "miércoles, 13 de octubre de 2021",
        author: "Guillermo Cruz Fuente",
        img: "https://cdn.pixabay.com/photo/2021/11/13/19/27/architecture-6792169_960_720.jpg",
        abstract:
            "El Presidente Municipal de San Marcos, Tomás Hernández Palma acompañado de ediles, hicieron entrega de un reconocimiento especial.",
    },
    {
        title: "El Centro Cultural La Sanmarqueña abre sus puertas",
        date: "miércoles, 13 de octubre de 2021",
        author: "Guillermo Cruz Fuente",
        img: "https://cdn.pixabay.com/photo/2019/12/19/03/05/christmas-4705290_960_720.jpg",
        abstract:
            "El Centro Cultural La Sanmarqueña abre sus puertas nuevamente para sus cursos de manera gratuita.",
    },
    {
        title: "Iniciaron los trabajos de limpieza y mantenimiento de los panteones",
        date: "miércoles, 13 de octubre de 2021",
        author: "Guillermo Cruz Fuente",
        img: "https://cdn.pixabay.com/photo/2017/03/28/12/21/autumn-2182010_960_720.jpg",
        abstract:
            "iniciaron los trabajos de limpieza y mantenimiento de los panteones en la cabecera municipal ubicados en la Colonia Revolución y Colonia Emiliano Zapata respectivamente.",
    },
    {
        title: "Se realiza la primera reunión de evaluación con los diferentes directores de área, del gobierno municipal",
        date: "miércoles, 13 de octubre de 2021",
        author: "Guillermo Cruz Fuente",
        img: "https://cdn.pixabay.com/photo/2016/02/03/17/38/coffee-break-1177540_960_720.jpg",
        abstract:
            "Se realiza la primera reunión de evaluación con los diferentes directores de área, del gobierno municipal que encabeza el licenciado Tomás Hernández Palma, dónde trataron diferentes temas de la agenda de trabajo en beneficio de la ciudadanía.",
    },
    {
        title: "Se llevó a cabo la primer reunión de Comisarios Municipales y Policías Rurales",
        date: "miércoles, 13 de octubre de 2021",
        author: "Guillermo Cruz Fuente",
        img: "https://cdn.pixabay.com/photo/2019/05/18/19/37/france-4212403_960_720.jpg",
        abstract:
            "El Honorable Cabildo de San Marcos, que preside Tomás Hernández Palma, llevó a cabo la primer reunión de Comisarios Municipales y Policías Rurales, donde se presentaron a los nuevos funcionarios municipales.",
    },
    {
        title: "Entrega de 15 tinacos de manera gratuita a familias de bajos recursos económicos",
        date: "miércoles, 13 de octubre de 2021",
        author: "Guillermo Cruz Fuente",
        img: "https://endomex.com.mx/wp-content/uploads/2012/06/TINACO-TVN-1100-EDITADA.jpg",
        abstract:
            "La dirección de Desarrollo Social a través de la gestión realizada ante el Gobierno del Estado, hizo entrega de 15 tinacos de manera gratuita a familias de bajos recursos económicos de nuestro municipio.",
    },
    {
        title: "Elementos de Protección Civil atendieron el reporte de un incendio",
        date: "miércoles, 13 de octubre de 2021",
        author: "Guillermo Cruz Fuente",
        img: "https://cdn.pixabay.com/photo/2018/04/03/20/04/ehrenamt-3287867_960_720.jpg",
        abstract:
            "Esta tarde, elementos de Protección Civil atendieron el reporte de un incendio en una tortillería en la Colonia Quinta Sección, el cual fue provocado por una fuga de gas. Todo quedo bajo control y afortunadamente no se reportaron personas lesionadas.",
    },
    {
        title: "Presentación de los directores de Seguridad Pública Municipal",
        date: "miércoles, 13 de octubre de 2021",
        author: "Guillermo Cruz Fuente",
        img: "https://cdn.pixabay.com/photo/2016/08/16/09/53/international-conference-1597531_960_720.jpg",
        abstract:
            "Presentación de los directores de Seguridad Pública Municipal: Gerardo Suástegui Nava, Tránsito Municipal: Miguel Ángel Gutiérrez Guerrero, Protección Civil: Diego Armando Valente Pineda, y Servicios Públicos: Eulalio Gutierrez Castro.",
    }
];